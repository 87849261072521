import React from 'react';
import { StickyWrapper } from '..';
import { doLogin } from '../../hooks';
import { configService } from '../../services';

export const LoginTile: React.FC = () => {
  return (
    <StickyWrapper>
      <div className='card p-top-1 p-bottom-2'>
        <div className='d-flex flex-column align-items-center'>
          <div className='text-center'>
            <h3>Join {configService.current().applicationTitle}</h3>
            <p>Score points, earn rewards and compete against other team members for big prizes. Do you have what it takes?</p>
            <div className='d-flex align-items-center justify-content-center flex-wrap'>
              <button className='btn btn-primary m-x-05 text-decoration-none' onClick={() => doLogin()}>LOGIN</button>
              <button className='btn btn-primary btn-hollow m-x-05 text-decoration-none' onClick={() => doLogin()}>REGISTER</button>
            </div>
          </div>
        </div>
      </div>
    </StickyWrapper>
  )
}