import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { HeaderBar, HeaderMenu, ProfileContainer } from './Header.styles';
import { useWindow } from '../../hooks';
import { Cell, Row, Hamburger, Progress, UserPicture, Menu, ProfileSummary } from '..';
import { useAppSelector, RootState } from '../../store';
import { apiService, configService } from '../../services';

export const Header: React.FC = () => {

  const { width } = useWindow();
  const { Grid } = useContext(ThemeContext);
  const userStatus = useAppSelector((state: RootState) => state.userStatus);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);

  const isLoggedIn = apiService.instance.isLoggedIn();

  const toggleMenu = () => {
    setProfileOpen(false);
    setMenuOpen(!isMenuOpen);
  };

  const toggleProfile = () => {
    setMenuOpen(false);
    setProfileOpen(!isProfileOpen);
  };

  const fullName = userStatus?.displayNameSafe ? userStatus.displayNameSafe : "Loading...";

  var pointsToGo = userStatus?.nextLevelIn - userStatus?.totalPoints;
  var pointsStart = userStatus?.nextLevelIn - userStatus?.currentLevelPoints;
  var pointDifference = pointsStart - pointsToGo;
  var percentage = Math.ceil((pointDifference / pointsStart) * 100);

  let appId = configService.current().theme.Id;
  let appName = configService.current().applicationTitle;

  return (
    <div className='pos-relative'>
      <HeaderBar>
        <Row>
          <Cell className='d-flex align-items-center'>
            <Hamburger isOpen={isMenuOpen} onClick={toggleMenu} />
          </Cell>
          <Cell className='d-flex align-items-center justify-content-center'>
            <Link className='d-block' to="/">
              <img src={`/images/${appId}/logo.svg`} alt={appName} className='d-block m-x-auto' width={width < Grid.Breakpoints.SM ? 75 : 100} />
            </Link>
          </Cell>
          <Cell className='d-flex justify-content-end'>
            {isLoggedIn && (<ProfileContainer className='w-100'>
              <div className='flex-1 m-right-1'>
                <p className='font-smallprint m-bottom-0 color-white text-center'>{userStatus?.currentLevel}</p>
                <div>
                  {/* <Progress max={userStatus?.nextLevelIn} value={userStatus ? userStatus?.totalPoints : 0} secondaryStyle /> */}
                  <div title={(percentage ? percentage : 0) + "%"}>
                    <Progress max={100} value={percentage} secondaryStyle />
                  </div>
                </div>
              </div>
              <div onClick={toggleProfile}>
                <UserPicture name={fullName} size={2} cssClass='m-x-auto cursor-pointer' />
              </div>
            </ProfileContainer>)}
          </Cell>
        </Row>
        {isMenuOpen && (
          <HeaderMenu position='left'>
            <Menu onMenuItemClick={toggleMenu} />
          </HeaderMenu>
        )}
        {isProfileOpen && isLoggedIn && (
          <HeaderMenu position='right'>
            <ProfileSummary />
          </HeaderMenu>
        )}
      </HeaderBar>
    </div>
  )
}