import { ContentUriType } from "outbound-api-client/lib/models";
import { Colours } from "../style/acmetechpep/colours";
import { AcmeTheme as Theme } from "../style/acmetechpep/theme";
import BidSupportTile from '../assets/images/tile-bidsupport.jpg';
import BidSupportMobileTile from '../assets/images/tile-bidsupport-mobile.jpg';
import CompatibilityTile from '../assets/images/tile-compatibility.jpg';
import CompatibilityMobileTile from '../assets/images/tile-compatibility-mobile.jpg';
import LegacyTile from '../assets/images/tile-legacy.jpg';
import LegacyMobileTile from '../assets/images/tile-legacy-mobile.jpg';
import { ConfigProps, LayoutType } from "./config.service.types";


export const AcmeTechConfig: ConfigProps = {
  appUri: `${process.env.REACT_APP_URL}`,
  apiUri: `${process.env.REACT_APP_API_URL}`,
  clientId: "acmetechpep",
  clientSecret: "GI4X9391eIcMLAozSUxtPSMR6x5Qhz2cyJH15BLBqRz+NdH+CQWVZACUfn901/6/QMpVsxQakhBDAQrpLRoGQw==",
  applicationTitle: "Acme Tech",
  campaignIdentifier: "",
  layoutType: LayoutType.AcmeTech,
  theme: Theme,
  colours: Colours,
  learnProps: {
    groupCategories: true
  },
  footerProps: {
    smallPrint: "&copy; Outbound Field Marketing Services",
    helpUrl: "mailto:help@focus-hub.co.uk?subject=Acme Tech Help",
    helpText: "Help",
    privacyUrl: "https://www.canon.co.uk/privacy-policy/",
    privacyText: "Privacy Information",
    termsUrl: null,
    termsText: null,
    cookiePolicyUrl: null,
    cookiePolicyText: null
  },
  enableProps: {
    groupByCategory: true,
    searchEnabled: true,
    downloadIcons: false,
    tiles: [
      {
        title: "Welcome to Enable",
        summary: "Learn more about the resources available.",
        url: "https://canonfocuspep.blob.core.windows.net/public/15515_Welcome%20to%20Focus%20-%20Digital%20Flyer.pdf",
        target: "_blank",
        thumnbails:
          [
            {
              identifier: 'Legacy',
              uri: LegacyTile,
              type: ContentUriType.ThumbnailWide
            },
            {
              identifier: 'Legacy-Mobile',
              uri: LegacyMobileTile,
              type: ContentUriType.ThumbnailTall
            },
          ]
      },
      {
        title: "Bid Support",
        summary: "Additional price support for sales.",
        url: "/bidsupport",
        target: "",
        thumnbails: [
          {
            identifier: 'Bid-Support',
            uri: BidSupportTile,
            type: ContentUriType.ThumbnailWide
          },
          {
            identifier: 'Bid-Support-Mobile',
            uri: BidSupportMobileTile,
            type: ContentUriType.ThumbnailTall
          },
        ]
      },
      {
        title: "Accessories",
        summary: "Check Canon accessory compatibility.",
        url: "https://canonfocuspep.blob.core.windows.net/public/15515_Canon%20Print%20Consumables%20Chart.pdf",
        target: "_blank",
        thumnbails: [
          {
            identifier: 'Compatibility',
            uri: CompatibilityTile,
            type: ContentUriType.ThumbnailWide
          },
          {
            identifier: 'Compatibility-Mobile',
            uri: CompatibilityMobileTile,
            type: ContentUriType.ThumbnailTall
          },
        ]
      }
    ]
  },
  campaignProps: {
    termsButtonText: "SUBMIT",
    termsButtonSubmittingText: "SUBMITTING",
    prizeSection: {
      show: false,
      title: null,
      intro: null,
      dividerImageUrl: null
    },
    dealSummarySections: []
  },
  opportunityManagementProps: {
    vendors: []
  },
  navigationProps: {
    showSalesTools: true,
    showQuiz: true,
    showLeaderboard: false
  }
}