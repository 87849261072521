import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { LayoutManager, Authentication } from './components';
import { routes } from './routes';
import { configService } from './services';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import CookieConsent from 'react-cookie-consent';
import { Helmet } from 'react-helmet';



export const App: React.FC = () => {

  let appId = configService.current().theme.Id;


  return (
    <div className={`${appId}`}>
      <Helmet>
        <link rel="shortcut icon" href={`./images/${appId}/favicon.ico`} type="image/x-icon" />
      </Helmet>
      <BrowserRouter>
        <Authentication>
          <ThemeProvider theme={configService.current().theme}>
            <Routes>
              <Route element={<LayoutManager />}>
                {routes.map((route, inx) => {
                  const Component = route.component;

                  return (
                    <Route key={inx} path={route.to} element={<Component />}>
                      {route.nestedRoutes && route.nestedRoutes.map((nestedRoute, inx) => {
                        const NestedComponent = nestedRoute.component;

                        return <Route key={inx} path={`${nestedRoute.to}`} element={<NestedComponent />} />
                      })}
                    </Route>
                  )
                })}
              </Route>
            </Routes>
            <ToastContainer
              position="top-right"
              autoClose={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              theme="light"
            />
            <CookieConsent
              location="bottom"
              buttonText="AGREE"
              cookieName={`${configService.current().clientId}-cookieconsent`}
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
            >
              This website requires the use of functional cookies which are necessary for it to function correctly. By clicking agree, you acknowledge this in accordance with our <a style={{ color: "#fff" }} href="https://outbound.blob.core.windows.net/cookies/cookiepolicy.html" target="_blank">Cookie Policy</a>.
            </CookieConsent>
          </ThemeProvider>
        </Authentication>
      </BrowserRouter>
    </div>
  );
}
